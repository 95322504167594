


































import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    drawer: false,
  }),
  mounted() {
    this.drawer = !this.$vuetify.breakpoint.smAndDown;
  },
});
