var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex",staticStyle:{"height":"100%"}},[_c('v-main',{staticClass:"white"},[_c('v-container',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('v-sheet',{staticClass:"pa-4 mb-2",attrs:{"rounded":"lg","min-height":"268"}},[_c('v-row',{staticClass:"pa-4 justify-space-between"},[_c('h1',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t('Processes.Process List')))]),_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":_vm.showDateFilter,"outlined":!_vm.showDateFilter},on:{"click":function($event){_vm.showDateFilter = !_vm.showDateFilter}}},[_c('v-badge',{attrs:{"color":"primary","value":_vm.appliedFiltersNumber,"content":_vm.appliedFiltersNumber}},[_vm._v(" "+_vm._s(_vm.$t('Processes.Filters'))+" "),_c('v-icon',[_vm._v("mdi-filter")])],1)],1)],1)]),(_vm.showDateFilter && !_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"filters"},[_c('v-container',[_c('td',[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"text","label":_vm.$t('Processes.Machine')},model:{value:(_vm.filters.machineFilter),callback:function ($$v) {_vm.$set(_vm.filters, "machineFilter", $$v)},expression:"filters.machineFilter"}})],1),_c('td',{staticClass:"input-select",staticStyle:{"width":"250px"}},[_c('v-select',{attrs:{"outlined":"","chips":"","dense":"","multiple":"","color":"secondary","width":"200","menu-props":{ maxHeight: '400' },"item-text":"text","item-value":"value","items":_vm.processTypes,"label":_vm.$t('Processes.Process name')},model:{value:(_vm.filters.processNameFilter),callback:function ($$v) {_vm.$set(_vm.filters, "processNameFilter", $$v)},expression:"filters.processNameFilter"}})],1),_c('td',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"auto","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","append-icon":"mdi-calendar","label":_vm.$t('Processes.Day from'),"dense":"","clearable":"","readonly":""},model:{value:(_vm.filters.startTimeFromFilter),callback:function ($$v) {_vm.$set(_vm.filters, "startTimeFromFilter", $$v)},expression:"filters.startTimeFromFilter"}},'v-text-field',attrs,false),on))]}}],null,false,61053882),model:{value:(_vm.showDatePicker1),callback:function ($$v) {_vm.showDatePicker1=$$v},expression:"showDatePicker1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.showDatePicker1 = false}},model:{value:(_vm.filters.startTimeFromFilter),callback:function ($$v) {_vm.$set(_vm.filters, "startTimeFromFilter", $$v)},expression:"filters.startTimeFromFilter"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('Processes.Day to'),"clearable":"","append-icon":"mdi-calendar","dense":"","readonly":""},model:{value:(_vm.filters.startTimeToFilter),callback:function ($$v) {_vm.$set(_vm.filters, "startTimeToFilter", $$v)},expression:"filters.startTimeToFilter"}},'v-text-field',attrs,false),on))]}}],null,false,2027221722),model:{value:(_vm.showDatePicker2),callback:function ($$v) {_vm.showDatePicker2=$$v},expression:"showDatePicker2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.showDatePicker2 = false}},model:{value:(_vm.filters.startTimeToFilter),callback:function ($$v) {_vm.$set(_vm.filters, "startTimeToFilter", $$v)},expression:"filters.startTimeToFilter"}})],1)],1),_c('td',[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"number","label":_vm.$t('Processes.Error')},model:{value:(_vm.filters.resultFilter),callback:function ($$v) {_vm.$set(_vm.filters, "resultFilter", $$v)},expression:"filters.resultFilter"}})],1),_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.resetFilters}},[_vm._v(_vm._s(_vm.$t('Action.Reset')))]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary"},on:{"click":_vm.applyFilters}},[_vm._v(_vm._s(_vm.$t('Action.Apply')))])],1)],1)],1):_vm._e(),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
              itemsPerPageOptions: _vm.itemsPerPageOptions,
            }},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.pageUpdated},scopedSlots:_vm._u([{key:"item.process_type_fullname",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Process', params: { id: item.uuid } }}},[_vm._v(" "+_vm._s(item.process_type_fullname)+" ")])]}},{key:"item.result",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.errors ? 'red' : 'green',"text-color":"white"}},[_vm._v(" "+_vm._s(item.result)+" ")])]}}])})],1)],1),(_vm.$vuetify.breakpoint.mobile)?_c('v-navigation-drawer',{attrs:{"right":"","app":"","mobile-breakpoint":_vm.$vuetify.breakpoint.mobileBreakpoint},model:{value:(_vm.showDateFilter),callback:function ($$v) {_vm.showDateFilter=$$v},expression:"showDateFilter"}},[(_vm.showDateFilter)?_c('div',{staticClass:"filters filters--mobile"},[_c('v-container',[_c('h1',{staticClass:"mb-8"},[_vm._v(_vm._s(_vm.$t('Process.Filters')))]),_c('v-row',[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"text","label":_vm.$t('Processes.Machine')},model:{value:(_vm.filters.machineFilter),callback:function ($$v) {_vm.$set(_vm.filters, "machineFilter", $$v)},expression:"filters.machineFilter"}})],1),_c('v-row',{staticClass:"input-select"},[_c('v-select',{attrs:{"outlined":"","chips":"","dense":"","multiple":"","color":"secondary","width":"200","menu-props":{ maxHeight: '400' },"item-text":"text","item-value":"value","items":_vm.processTypes,"label":_vm.$t('Processes.Process name')},model:{value:(_vm.filters.processNameFilter),callback:function ($$v) {_vm.$set(_vm.filters, "processNameFilter", $$v)},expression:"filters.processNameFilter"}})],1),_c('v-row',[_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","append-icon":"mdi-calendar","label":_vm.$t('Processes.Day from'),"dense":"","clearable":"","readonly":""},model:{value:(_vm.filters.startTimeFromFilter),callback:function ($$v) {_vm.$set(_vm.filters, "startTimeFromFilter", $$v)},expression:"filters.startTimeFromFilter"}},'v-text-field',attrs,false),on))]}}],null,false,61053882),model:{value:(_vm.showDatePicker1),callback:function ($$v) {_vm.showDatePicker1=$$v},expression:"showDatePicker1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.showDatePicker1 = false}},model:{value:(_vm.filters.startTimeFromFilter),callback:function ($$v) {_vm.$set(_vm.filters, "startTimeFromFilter", $$v)},expression:"filters.startTimeFromFilter"}})],1),_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('Processes.Day to'),"clearable":"","append-icon":"mdi-calendar","dense":"","readonly":""},model:{value:(_vm.filters.startTimeToFilter),callback:function ($$v) {_vm.$set(_vm.filters, "startTimeToFilter", $$v)},expression:"filters.startTimeToFilter"}},'v-text-field',attrs,false),on))]}}],null,false,2027221722),model:{value:(_vm.showDatePicker2),callback:function ($$v) {_vm.showDatePicker2=$$v},expression:"showDatePicker2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.showDatePicker2 = false}},model:{value:(_vm.filters.startTimeToFilter),callback:function ($$v) {_vm.$set(_vm.filters, "startTimeToFilter", $$v)},expression:"filters.startTimeToFilter"}})],1)],1),_c('v-row',[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"number","label":_vm.$t('Processes.Error')},model:{value:(_vm.filters.resultFilter),callback:function ($$v) {_vm.$set(_vm.filters, "resultFilter", $$v)},expression:"filters.resultFilter"}})],1),_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.resetFilters}},[_vm._v(_vm._s(_vm.$t('Action.Reset')))]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary"},on:{"click":_vm.applyFilters}},[_vm._v(_vm._s(_vm.$t('Action.Apply')))])],1)],1)],1):_vm._e()]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }