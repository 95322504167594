





























import ProcessByDayChart, { ProcessByWeekData } from '@/charts/dashboard/ProcessByWeekColumnChart';
import ProcessTypesPie, { ProcessInfoType, ProcessTypesPieData } from '@/charts/dashboard/ProcessTypesPieChart';
import { PROCESS_TYPES_VALUE_MAP_VIEW } from '@/models/ProcessTypeEnum';
import Vue from 'vue';


export default Vue.extend({
  created() {
    this.$http.dashboard.dashboardTypes().then((resp) => {
      this.processTypeChart = resp.data as ProcessTypesPieData;
    });
    this.$http.dashboard.dashboardWeeks().then((resp) => {
      this.processWeekChart = resp.data as ProcessByWeekData;
    });
    this.$http.user.userRead('current').then((resp)=>{
        this.userName = resp.data.first_name || ''
    })
  },
  computed: {
    processTypesPieChart(): any {
      if (!this.processTypeChart) return;
      const processTypeChartView = this.processTypeChart.map(({ name, y }) => ({
        name: (PROCESS_TYPES_VALUE_MAP_VIEW as any)[+name],
        y,
      }));
      return this.loaded
        ? ProcessTypesPie(processTypeChartView, {
            title: this.$t('Dashboard.ProcessTypesPie.Process types').toString(),
            seriesLabel: this.$t('Dashboard.ProcessTypesPie.Percentage of process type').toString(),
            noData: this.$t('Charts.No data').toString(),
          })
        : null;
    },
    processWeekColumnChart(): any {
      if (!this.processWeekChart) return;

      return this.loaded
        ? ProcessByDayChart(
            this.processWeekChart,
            {
              title: this.$t('Dashboard.ProcessByWeekColumn.Processes by week').toString(),
              seriesLabel: this.$t('Dashboard.ProcessByWeekColumn.Number of processes').toString(),
              noData: this.$t('Charts.No data').toString(),
            },
            [
              this.$vuetify.theme.themes.light.primary?.toString() ?? '#fffff',
              this.$vuetify.theme.themes.light.secondary?.toString() ?? '#fffff',
            ],
          )
        : null;
    },
  },
  data() {
    return {
      processTypeChart: null as ProcessTypesPieData | null,
      processWeekChart: null as ProcessByWeekData | null,
      loaded: true,
      userName: '',
      processInfo: [
        {description: this.$t('DashboardProcessStep.Step 1').toString(), image:'img/step-1.png'},
        {description: this.$t('DashboardProcessStep.Step 2').toString(), image:'img/step-2.png'},
        {description: this.$t('DashboardProcessStep.Step 3').toString(), image:'img/step-3.png'},
        {description: this.$t('DashboardProcessStep.Step 4').toString(), image:'img/step-4.png'},
        {description: this.$t('DashboardProcessStep.Step 5').toString(), image:'img/step-5.png'}
    ] as ProcessInfoType | null
    };
  },
});
