






































import Vue, { PropType } from 'vue';
import { mapActions } from 'vuex';
import { ProcessStateList } from '@/http/HttpResponses';
import { ExecutionDetailsSerialiser } from '@/http/myApi';
import StatsTable from './StatsTable.vue';
import ChartPage from './ChartPage.vue';
import MainWrapper from '../containers/MainWrapper.vue';
import DataTree, { ItemType } from '../components/DataTree.vue';

export type Breadcrumps = Array<{
  text: string;
  disabled: boolean;
  href?: string;
  to?: any;
  exact?: boolean;
}>;

export default Vue.extend({
  props: {
    id: String,
    processItemsTree: {
      type: Array as PropType<ItemType[]>,
    },
  },
  computed: {
    breadcrumps(): Breadcrumps {
      return [
        {
          text: this.$t('Processes.Process List').toString(),
          disabled: false,
          to: { name: 'ProcessList' },
          exact: true,
        },
        {
          text: this.currentProcess?.process_type_fullname ?? '',
          disabled: true,
          href: 'Process',
        },
      ];
    },
    selectedItem(): string[] {
      if (this.id) {
        return [this.id];
      } else {
        return [this.processItemsTree[0].children[0].children[0].id];
      }
    },
  },
  data(): {
    pdfTable: Vue | null;
    chartData: ProcessStateList | null;
    processLoading: boolean;
    currentProcess: ExecutionDetailsSerialiser | null;
    processTable: string[][];
    note: string;
    noteLoading: boolean;
  } {
    return {
      pdfTable: null,
      chartData: {
        loading: true,
        process_temp: [],
        chamber_pressure: [],
      },
      processLoading: true,
      currentProcess: null,
      processTable: [[]],
      note: '',
      noteLoading: false,
    };
  },
  components: {
    StatsTable,
    ChartPage,
    MainWrapper,
    DataTree,
  },
  mounted() {
    this.pdfTable = (this.$refs.tableWrapper as Vue).$refs.pdfTable as Vue;
    if (this.id) {
      this.selectionChanged(this.id);
    }
  },
  methods: {
    ...mapActions(['showSuccessSnackbar', 'showFailedSnackbar']),

    updateNote() {
      if (!this.currentProcess?.uuid) return;
      this.noteLoading = true;
      this.$http.process
        .processUserComment(this.currentProcess.uuid, {
          user_comment: this.note,
        })
        .then(() => this.showSuccessSnackbar(this.$t('Snackbar.Success')))
        .catch(() => this.showFailedSnackbar(this.$t('Process.Error')))
        .finally(() => (this.noteLoading = false));
    },
    selectionChanged(uuid: string) {
      this.processLoading = true;
      this.$http.process.processRead(uuid).then((process) => {
        if (this.$route.params.id !== uuid) {
          this.$router.replace({ name: 'Process', params: { id: uuid } });
        }
        this.currentProcess = process.data;
        this.note = process.data.user_comment ?? '';
        setTimeout(() => {
          this.processLoading = false;
          const chartData = process.data.process_state_list as unknown;
          this.chartData = chartData as ProcessStateList;
        }, 150);
      });

      this.$http.process.processDetails(uuid).then((resp) => {
        this.processTable = resp.data as any;
      });
    },
  },
});
