













import Vue, { PropType } from 'vue';

import { ProcessStateList } from '@/http/HttpResponses';
import {
  getData,
  ProcessLineChartTranslations,
} from '@/charts/ProcessLineChart';
import { mapState } from 'vuex';

export default Vue.extend({
  props: {
    chartData: Object as PropType<ProcessStateList>,
    loading: Boolean,
  },

  computed: {
    ...mapState('user', ['settings']),

    chartOptions(): Highcharts.Options | null {
      const translations: ProcessLineChartTranslations = {
        Chart: {
          Time: this.$t('Chart.Time').toString(),
          Temperature: this.$t('Chart.Temperature').toString(),
          Pressure: this.$t('Chart.Pressure').toString(),
        },
        noData: this.$t('Charts.No data').toString(),
      };
      return this.getChartOptions(translations);
    },
  },
  watch: {
    loading() {
      if (this.loading) {
        (this.$refs.chart as any).chart.showLoading();
      } else {
        (this.$refs.chart as any).chart.hideLoading();
      }
    },
  },
  methods: {
    getChartOptions(
      translations: ProcessLineChartTranslations,
    ): Highcharts.Options {
      return getData(this.chartData, translations, this.settings.metricUnits, [
        this.$vuetify.theme.themes.light.primary?.toString() ?? '#fffff',
        this.$vuetify.theme.themes.light.secondary?.toString() ?? '#fffff',
      ]);
    },
  },
});
