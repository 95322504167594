

































import Vue, { PropType } from 'vue';

export interface ItemType {
  id: string;
  name: string;
  children: {
    id: string;
    name: string;
    children: {
      id: string;
      name: string;
    }[];
  }[];
}

export default Vue.extend({
  props: {
    items: Array as PropType<ItemType[]>,
    selected: Array,
  },
  data() {
    return {
      search: null,
      caseSensitive: false,
    };
  },
  computed: {
    open(): any {
      if (!this.items) return null;

      const openTime = this.selected[0];
      let openDay = null;
      let openSerial = null;

      this.items.map(
        (currSerial) =>
          // eslint-disable-next-line
          currSerial.children.map((currDay): void => {
            const selectedTime = currDay.children.find(
              (currTime) => currTime.id === this.selected[0],
            );
            if (selectedTime) {
              openSerial = currSerial.id;
              openDay = currDay?.id;
            }
          }),
        // eslint-disable-next-line function-paren-newline
      );
      return [openSerial, openDay, openTime];
    },
    filter(): ((item: any, search: string, textKey: string) => boolean) | undefined {
      return this.caseSensitive
        ? (item: any, search: string, textKey: string) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
  methods: {
    selectionChanged(event: string[]) {
      this.$emit('selection-changed', event[0]);
    },
  },
});
