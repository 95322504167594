export type ProcessByWeekData = {
  name: string;
  y: number;
}[];

export type ProcessByWeekTranslations = {
  title: string;
  seriesLabel: string;
  noData: string;
};

export const getData = (
  chartData: ProcessByWeekData,
  translations: ProcessByWeekTranslations,
  colors: string[],
): any => {
  const categories = chartData.map((x) => x.name);

  return {
    chart: {
      type: 'column',
    },
    lang: {
      noData: translations.noData,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
    },
    title: {
      text: translations.title,
    },
    xAxis: {
      categories,
    },
    yAxis: {
      min: 0,
      title: {
        text: translations.seriesLabel,
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        color: colors[0],
        name: translations.seriesLabel,
        data: [...chartData],
      },
    ],
  };
};

export default getData;
