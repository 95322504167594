export type ProcessTypesPieData = { name: string; y: number }[];
export type ProcessTypesPieTranslations = {
  title: string;
  seriesLabel: string;
};
export type ProcessInfoType = { description: string; image: string }[];

// Build the chart
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getData = (
  chartData: ProcessTypesPieData,
  translations: { title: string; seriesLabel: string; noData: string },
) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    lang: {
      noData: translations.noData,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: translations.title,
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        },
        showInLegend: false,
      },
    },
    series: [
      {
        name: translations.seriesLabel,
        colorByPoint: true,
        data: [...chartData],
      },
    ],
  };
};

export default getData;
