import { addBrackets, getPressureUnit, getTemperatureUnit } from '@/core/UnitsUtils';
import { ProcessStateList } from '@/http/HttpResponses';
// eslint-disable-next-line object-curly-newline
import { OptionsLayoutValue, AlignValue, VerticalAlignValue, defaultOptions } from 'highcharts';

export interface ProcessLineChartTranslations {
  Chart: {
    Time: string;
    Temperature: string;
    Pressure: string;
  };
  noData: string;
}

export const getData: (
  chartData: ProcessStateList,
  translations: ProcessLineChartTranslations,
  isMetricUnit: boolean,
  colors: string[],
) => Highcharts.Options = (chartData, translations, isMetricUnit, colors) => ({
  chart: {
    alignTicks: false,
    events: {
      load() {
        if (chartData.loading) {
          this.showLoading();
        }
      },
    },
  },
  lang: {
    noData: translations.noData,
  },
  legend: {
    layout: 'horizontal' as OptionsLayoutValue,
    align: 'center' as AlignValue,
    verticalAlign: 'top' as VerticalAlignValue,
    floating: false,
    backgroundColor: defaultOptions?.legend?.backgroundColor || 'rgba(255,255,255,0.25)', // theme
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  xAxis: {
    type: 'datetime',
    title: {
      text: translations.Chart.Time,
    },
    dateTimeLabelFormats: {
      second: '%M:%S',
      minute: '%M:%S',
      hour: '%H:%M:%S',
      day: '%M:%S',
    },
  },
  yAxis: [
    {
      labels: {
        format: '{value}' + getTemperatureUnit(isMetricUnit),
        style: {
          color: colors[0],
        },
      },
      title: {
        text: translations.Chart.Temperature + ' ' + addBrackets(getTemperatureUnit(isMetricUnit)),
        style: {
          color: colors[0],
        },
      },
    },
    {
      title: {
        text: translations.Chart.Pressure + ' ' + addBrackets(getPressureUnit(isMetricUnit)),
        style: {
          color: colors[1],
        },
      },
      labels: {
        format: '{value} ' + getPressureUnit(isMetricUnit),
        style: {
          color: '#00000',
        },
      },
      opposite: true,
    },
  ],
  series: [
    {
      name: translations.Chart.Temperature + ' ' + addBrackets(getTemperatureUnit(isMetricUnit)),
      type: 'line',
      yAxis: 0,
      color: colors[0],
      data: chartData?.process_temp,
      tooltip: {
        xDateFormat: '%M:%S',
        valueSuffix: ' ' + getTemperatureUnit(isMetricUnit),
        valueDecimals: 1,
      },
    },
    {
      name: translations.Chart.Pressure + ' ' + addBrackets(getPressureUnit(isMetricUnit)),
      type: 'spline',
      yAxis: 1,
      color: colors[1],
      data: chartData?.chamber_pressure,
      tooltip: {
        xDateFormat: '%M:%S',
        valueSuffix: ' ' + getPressureUnit(isMetricUnit),
        valueDecimals: 2,
      },
    },
  ],
});
