































import { ITEMS_PER_PAGE_OPTIONS } from '@/core/Constants';
import { MachineListQueryParams } from '@/http/ProcessListQueryParams';
import { ListOptions } from '@/models/ListsModel';
import Vue from 'vue';
import { mapActions } from 'vuex';

export default Vue.extend({
  data(): {
    items: Array<{
      machine: string | undefined;
      version: number | undefined;
      is_demo: boolean | undefined;
      user_comment: string | undefined;
    }>;
    options: ListOptions;
    itemsPerPageOptions: number[];
    totalItems: number;
    loading: boolean;
  } {
    return {
      items: [],
      options: {} as ListOptions,
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      totalItems: 0,
      loading: true,
    };
  },
  computed: {
    headers(): {
      text: string;
      value: string;
      align?: string;
    }[] {
      return [
        {
          text: this.$t('Machines.Machine').toString(),
          value: 'machine',
          align: 'start',
        },
        {
          text: this.$t('Machines.Friendly name').toString(),
          value: 'user_comment',
        },
        {
          text: this.$t('Machines.Machine Version').toString(),
          value: 'version',
        },
        {
          text: this.$t('Machines.Highest process number').toString(),
          value: 'max_execution_counter',
        },
        { text: this.$t('Machines.Is demo').toString(), value: 'is_demo' },
      ];
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['showFailedSnackbar']),
    getOptionsToOrdering(options: ListOptions) {
      if (!(options.sortBy?.length || options.sortDesc?.length))
        return undefined;

      // adapting fields
      let sortBy =
        options.sortBy[0] === 'machine' ? 'serial_number' : options.sortBy[0];
      sortBy = options.sortBy[0] === 'version' ? 'machine_version' : sortBy;

      return (options.sortDesc[0] ? '-' : '') + sortBy;
    },

    getDataFromApi() {
      const paramsAdapter: MachineListQueryParams = {
        page: this.options.page,
        page_size: this.options.itemsPerPage,
        ordering: this.getOptionsToOrdering(this.options),
      };

      return this.$http.machine
        .machineList(paramsAdapter)
        .then((machines) => {
          this.totalItems = machines.data.count;

          this.items = machines.data.results.map((machine) => ({
            machine: machine.serial_number,
            version: machine.machine_version,
            is_demo: machine.is_demo,
            user_comment: machine.user_comment,
            max_execution_counter: machine.max_execution_counter,
          }));
        })
        .catch(() =>
          this.showFailedSnackbar(this.$t('Processes.Cannot fetch list')),
        )
        .finally(() => (this.loading = false));
    },
  },
});
