




import Vue from 'vue';
import { ItemType } from '../components/DataTree.vue';
import { ProcessTree } from '@/http/HttpResponses';
import { initProcessListState } from '@/store/process';
import { mapActions } from 'vuex';

export default Vue.extend({
  data(): {
    processItemsTree: ItemType[];
    processListLoading: boolean;
  } {
    return {
      processItemsTree: [],
      processListLoading: true,
    };
  },
  mounted() {
    this.$http.process
      .processTreeList()
      .then((resp) => {
        const data = (resp as any).data.results as ProcessTree;

        this.processItemsTree = Object.keys(data).map((serial) => {
          return {
            id: serial,
            name: serial,
            children: Object.keys(data[serial]).map((day) => {
              return {
                id: day,
                name: day,
                children: Object.keys(data[serial][day]).map((time) => {
                  return { id: data[serial][day][time as any], name: time };
                }),
              };
            }),
          } as ItemType;
        }, {});
      })
      .catch(() => {
        this.showFailedSnackbar('Processes.Error');
      })
      .finally(() => {
        this.processListLoading = false;
      });
  },
  destroyed() {
    this.$store.commit('process/setFilters', initProcessListState().filters);
    this.$store.commit('process/setOptions', initProcessListState().options);
  },
  methods: {
    ...mapActions(['showFailedSnackbar']),
  },
});
