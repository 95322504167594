






























import Vue, { PropType } from 'vue';
import { addBrackets, getPressureUnit, getTemperatureUnit } from '@/core/UnitsUtils';
import { mapState } from 'vuex';

export default Vue.extend({
  props: {
    dense: {
      type: Boolean,
      default: true,
    },
    tableData: {
      type: Array as PropType<(number | string)[][]>,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['settings']),
    tableDataView(): (number | string)[][] {
      return this.tableData.map((row) => {
        if (typeof row[3] === 'number') {
          row[3] = row[3].toFixed(2);
        }
        if (typeof row[4] === 'number') {
          row[4] = row[4].toFixed(1);
        }
        return row;
      });
    },
    getPressureUnit(): string {
      return addBrackets(getPressureUnit(this.settings.metricUnits));
    },
    getTemperatureUnit(): string {
      return addBrackets(getTemperatureUnit(this.settings.metricUnits));
    },
  },
});
