



































































































































































































































































import { AxiosResponse } from 'axios';
import Vue from 'vue';
import { mapActions } from 'vuex';

import { ExecutionDetailsSerialiser } from '@/http/myApi';
import { ProcessListResponse } from '@/http/ProcessHttpResponses';
import { ProcessListQueryParams } from '@/http/ProcessListQueryParams';
import { ListOptions } from '@/models/ListsModel';
import { PROCESS_TYPES_SELECT } from '@/models/ProcessTypeEnum';
import { AppState } from '@/store';
import { ProcessFiltersState, ProcessListState } from '@/store/process';

import { ITEMS_PER_PAGE_OPTIONS } from '@/core/Constants';
import { formatDateTimeView } from '@/core/DateUtils';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
  computed: {
    showMobileDateFilters(): boolean {
      return this.showDateFilter && this.$vuetify.breakpoint.mobile;
    },
    processFilters: {
      get: function (): ProcessFiltersState {
        return (this.$store.state as AppState).process.filters;
      },
      set: function (newValue: ProcessFiltersState) {
        this.$store.commit('process/setFilters', newValue);
      },
    },
    processOptions: {
      get: function (): ListOptions {
        return (this.$store.state as AppState).process.options;
      },
      set: function (newValue: ListOptions) {
        this.$store.commit('process/setOptions', newValue);
      },
    },
    items(): {
      serial_number: string | undefined;
      process_type_name: string | undefined;
      process_type_fullname: string;
      uuid: string | undefined;
      start_date: string | undefined;
      seconds_taken: number | undefined;
      result: string | null;
      errors: boolean;
    }[] {
      return this.processItems.map((process) => {
        const statusCodes = process.end_status_codes as Array<number>;
        return {
          serial_number: process.machine?.serial_number,
          process_type_name: process.process_type_name,
          process_type_fullname: process.process_type_fullname ?? '',
          uuid: process.uuid,
          start_date: formatDateTimeView(process.start_date),
          seconds_taken: Math.ceil(
            (process.seconds_taken ? process.seconds_taken : 0) / 60,
          ),
          errors: !!statusCodes.length,
          result: statusCodes.length
            ? statusCodes[0].toString()
            : this.$t('Success').toString(),
        };
      });
    },
    headers(): {
      text: string | TranslateResult;
      value: string;
      align?: string;
      sortable?: boolean;
    }[] {
      return [
        {
          text: this.$t('Processes.Machine'),
          align: 'start',
          value: 'serial_number',
        },
        {
          text: this.$t('Processes.Process full name'),
          value: 'process_type_fullname',
          sortable: false,
        },
        {
          text: this.$t('Processes.Process name'),
          value: 'process_type_name',
          sortable: false,
        },
        { text: this.$t('Processes.Start time'), value: 'start_date' },
        { text: this.$t('Processes.Duration (min)'), value: 'seconds_taken' },
        { text: this.$t('Processes.Result'), value: 'result', sortable: false },
      ];
    },
  },
  data() {
    return {
      appliedFiltersNumber: 0,
      filterDrawer: false,
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      processTypes: PROCESS_TYPES_SELECT,
      showDateFilter: false,
      showDatePicker1: false,
      showDatePicker2: false,

      totalItems: 0,
      options: {} as ListOptions,
      filters: {
        machineFilter: '',
        processNameFilter: [],
        startTimeFromFilter: null as string | null,
        startTimeToFilter: null as string | null,
        resultFilter: null as string | null,
      } as ProcessFiltersState,
      processItems: [] as ExecutionDetailsSerialiser[],
      loading: true,
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },

  mounted() {
    this.filters = { ...this.processFilters };
    this.countFiltersApplied();
    this.options = { ...this.processOptions };
  },
  methods: {
    ...mapActions(['showFailedSnackbar']),
    pageUpdated() {
      this.processOptions = { ...this.options };
    },
    getOptionsToOrdering(options: ListOptions) {
      if (!(options.sortBy?.length || options.sortDesc?.length))
        return undefined;

      // adapting fields
      const sortBy =
        options.sortBy[0] === 'serial_number'
          ? 'machine__serial_number'
          : options.sortBy[0];

      return (options.sortDesc[0] ? '-' : '') + sortBy;
    },
    getDataFromApi() {
      const filters = this.processFilters as ProcessFiltersState;

      const paramsAdapter: ProcessListQueryParams = {
        page: this.options.page,
        page_size: this.options.itemsPerPage,
        ordering: this.getOptionsToOrdering(this.options),
        start_date_begin: filters.startTimeFromFilter
          ? new Date(filters.startTimeFromFilter).toISOString().split('.')[0]
          : undefined,
        start_date_end: filters.startTimeToFilter
          ? new Date(filters.startTimeToFilter).toISOString().split('.')[0]
          : undefined,
        process_type:
          filters.processNameFilter.reduce((acc, curr) => {
            if (acc) {
              acc += ', ' + curr;
            } else acc += curr;
            return acc;
          }, '') || undefined,
        machine: filters.machineFilter || undefined,
        end_status_codes: filters.resultFilter || undefined,
      };

      this.$http.process
        .processList(paramsAdapter as any)
        .then((resp) => {
          const processListData = (
            resp as unknown as AxiosResponse<ProcessListResponse>
          ).data;

          this.processItems = processListData.results as any;

          this.totalItems = processListData.count;
        })
        .catch(() =>
          this.showFailedSnackbar(this.$t('Processes.Cannot fetch list')),
        )
        .finally(() => (this.loading = false));
    },
    resetFilters() {
      this.options.page = 1;

      if (this.$vuetify.breakpoint.mobile) {
        this.showDateFilter = false;
      }

      this.loading = true;
      this.filters.machineFilter = '';
      this.filters.processNameFilter = [];
      this.filters.startTimeFromFilter = '';
      this.filters.startTimeToFilter = '';
      this.filters.resultFilter = '';

      this.processItems = [];

      this.setListsState();

      this.getDataFromApi();
    },
    countFiltersApplied() {
      this.appliedFiltersNumber = Object.keys(this.processFilters).reduce(
        (acc, curr) => {
          let value = (this.processFilters as any)[curr];

          if (!value) return acc;
          else {
            if (!Array.isArray(value)) return (acc += 1);
            else if (value.length) return (acc += 1);
            return acc;
          }
        },
        0,
      );
    },
    setListsState() {
      const processList: ProcessListState = {
        filters: {
          machineFilter: this.filters.machineFilter,
          processNameFilter: this.filters.processNameFilter,
          startTimeFromFilter: this.filters.startTimeFromFilter ?? '',
          startTimeToFilter: this.filters.startTimeToFilter ?? '',
          resultFilter: this.filters.resultFilter ?? '',
        },
        options: { ...this.options },
      };

      this.processFilters = processList.filters;
      this.processOptions = { ...this.options };

      this.countFiltersApplied();
    },
    applyFilters() {
      this.options.page = 1;

      if (this.$vuetify.breakpoint.mobile) {
        this.showDateFilter = false;
      }
      this.loading = true;
      this.setListsState();

      this.getDataFromApi();
    },
  },
});
