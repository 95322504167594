

























































































import Vue, { PropType } from 'vue';
import { ProcessSingle, ProcessStateList } from '@/http/HttpResponses';
import Chart from '@/modules/viewer/containers/Chart.vue';
// import PrintChart from '@/modules/viewer/components/PrintChart.vue';
import { format } from 'date-fns';

export default Vue.extend({
  props: {
    tableElem: Vue,
    chartData: Object as PropType<ProcessStateList>,
    loading: Boolean,
    process: Object as PropType<ProcessSingle>,
  },
  components: {
    Chart,
    // PrintChart,
  },
  data() {
    return {
      delayChart: true,
    };
  },
  computed: {
    statusCode(): number {
      const statusCodes = this.process?.end_status_codes as Array<number>;
      return statusCodes.length > 0 ? statusCodes[0] : 0;
    },
    processResult(): string {
      return this.statusCode
        ? this.statusCode.toString()
        : this.$t('Success').toString();
    },
    startDate(): string {
      return this.process?.start_date
        ? format(new Date(this.process?.start_date), 'HH:mm')
        : '';
    },
    endDate(): string {
      return this.process?.end_date
        ? format(new Date(this.process?.end_date), 'HH:mm')
        : '';
    },
    date(): string {
      return this.process?.end_date
        ? format(new Date(this.process?.end_date), 'dd-MM-yyyy')
        : '';
    },
  },
  mounted() {
    setTimeout(() => {
      this.delayChart = false;
    }, 200);
  },
});
